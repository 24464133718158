import { useLottie } from 'lottie-react';
import { createPage, Block, ButtonLink, Flex, Title, Container } from 'react-commons';
import Meta from '@/components/Meta';

import Pod from '@/components/Pod';

import puzzleAnimation from '@/lib/lottie/puzzle.json';
import FluidLayout from '@/layouts/FluidLayout';

import style from './error.module.scss';

export interface NotFoundPageProps {
}

export default createPage<NotFoundPageProps>('NotFoundPage', { style }, function NotFoundPage ({ className }) {
  const { View: LottieAnimation } = useLottie({
    animationData: puzzleAnimation,
    loop: true
  });

  return (
    <FluidLayout className={className}>

      <Container>

        {/* Meta information */}
        <Meta 
          title='Page Not Found | Shockwave Games'
          titleTag='Page Not Found | Shockwave Games'
        />

        <Block className='NotFoundPage__Content'>
          <Flex directionColumn>
            <Block>
              <Title>404 Page Not Found</Title>
            </Block>

            <Block>
              <p>Whoops! Looks like we're missing that puzzle piece.</p>
            </Block>

            <Block>
              {LottieAnimation}
            </Block>

            <Block className='--textCenter'>
              <ButtonLink secondary rounded href='/'>Back to Home</ButtonLink>
            </Block>
          </Flex>
        </Block>

      </Container>

    </FluidLayout>
  );
});
